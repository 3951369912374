<template>
  <div class="popup_wrap doc_req_pop" style="width:950px; height:600px;">
    <form id="frm">
      <button class="layer_close" @click="$emit('close')">
        <span>close</span>
      </button>
      <div class="popup_cont">
        <h1 class="page_title text_center">
          CONTAINER NO. CORRECTION REQUEST
        </h1><!-- CONTAINER NO. CORRECTION REQUEST -->
        <div class="content_box">
          <!-- content_box -->
          <!-- content_box -->
          <h2 class="content_title">
            1. Overview
          </h2><!-- 스케쥴 -->
          <table class="tbl_row mt10">
            <colgroup>
              <col style="width:20%">
              <col style="width:40%">
              <col style="width:40%">
            </colgroup>
            <tbody>
              <tr>
                <th class="text_center">
                  Date
                </th>
                <td colspan="2"> {{ today }}</td>
                <td></td>
              </tr>
              <tr>
                <th class="text_center">
                  To
                </th>
                <td colspan="2">고려해운(주)</td>
                <td></td>
              </tr>
              <tr>
                <th class="text_center" rowspan="2">
                  FROM
                </th>
                <td>{{ formData.shprCstEnm }}</td>
                <td>{{ formData.shprCstNm }}</td>
              </tr>
              <tr>
                <td>
                  <input
                    v-model="formData.shprCstTelNo"
                    :id="'shprCstTelNo'"
                    type="text"
                    placeholder="Tel"
                  >
                </td>
                <td>
                  <input
                    v-model="formData.shprCstEml"
                    id="shprCstEml"
                    type="text"
                    placeholder="E-mail"
                    @input="checkErrorVisible('shprCstEml')"
                    @blur="checkValidationEmail('shprCstEml')"
                  >
                </td>
              </tr>
              <tr>
                <th class="text_center" rowspan="2">
                  Booking Shipper
                </th>
                <td colspan="2">{{ formData.shprCstEnm }}</td>
              </tr>
            </tbody>
          </table>

          <h2 class="content_title">
            2. B/L Information
          </h2>
          <table class="tbl_row mt10">
            <colgroup>
              <col style="width:20%">
              <col style="width:40%">
              <col style="width:40%">
            </colgroup>
            <tbody>
              <tr>
                <th class="text_center">
                  B/L No.
                </th>
                <td colspan="2">
                  {{ formData.blNo }}
                </td>
                <td></td>
              </tr>
              <tr>
                <th class="text_center">
                  POL/POD
                </th>
                <td colspan="2">{{ formData.polPlcNm }} / {{ formData.podPlcNm }}</td>
                <td></td>
              </tr>
              <tr>
                <th class="text_center">
                  VSL/VOY
                </th>
                <td colspan="2">{{ formData.vslNm }} / {{ formData.voyNo }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>

          <h2 class="content_title">
            3. Request Details<br><br>
            We hearby request you for the under mentioned corrections.
          </h2>
          <table class="tbl_row mt10">
            <colgroup>
              <col style="width:20%">
              <col style="width:40%">
              <col style="width:40%">
            </colgroup>
            <tbody>
              <tr>
                <th class="text_center">
                </th>
                <th class="text_center">NOW READ (FROM)</th>
                <th class="text_center">SHOULD BE READ (TO)</th>
                <th>
                </th>
              </tr>
              <tr v-for="(vo, idx) in containerInfo" :key="'cntr_' + idx">
                <th class="text_center">
                  Container No.
                </th>
                <td>
                  <input
                    v-model="vo.bfCntrNo"
                    :id="'bfCntrNo_' + idx"
                    type="text"
                    style="width:200px;"
                    maxlength="11"
                    @input="changeOnlyUpperEng($event, vo, 'bfCntrNo', idx)"
                    @blur="checkValidationBfCntrNo(idx)"
                  >
                </td>
                <td>
                  <input
                    v-model="vo.updtCntrNo"
                    :id="'updtCntrNo_' + idx"
                    type="text"
                    style="width:200px;"
                    maxlength="11"
                    @input="changeOnlyUpperEng($event, vo, 'updtCntrNo', idx)"
                    @blur="checkValidationAfCntrNo(idx)"
                  >
                  <button
                    v-if="idx === 0"
                    class="tbl_icon plus"
                    style="margin-left: 5px;"
                    @click.prevent="addBLContainer(idx)"
                  >
                    plus
                  </button>
                  <button
                    v-if="idx !== 0"
                    class="tbl_icon minus"
                    style="margin-left: 5px;"
                    @click.prevent="removeBLContainer(idx)"
                  >
                    minus
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text_center mt10">
          <a
            href="#none"
            class="button blue lg ml_auto mr5"
            @click.prevent="cntrNoCorrectReqInsert()"
          >{{ $t('msg.MYIN040G010.062') }}</a> <!-- 임시저장 -->
          <a
            v-show="reqTempSaveYn === 'Y'"
            href="#none"
            class="button blue lg ml_auto mr5"
            @click="cntrNoCorrectReqDelete()"
          >{{ $t('art.CMBA660.007') }}</a> <!-- 삭제 -->
          <a
            href="#none"
            class="button gray lg"
            @click="reqCntrNoCorrSave()"
          >{{ $t('js.booking.044') }}</a><!-- 제출하기 -->&nbsp;
          <a href="#none" class="button gray lg" @click="$emit('close')()">{{ $t('msg.ONEX010P095.057') }}</a>
        </div>
        <win-layer-pop class="popup_dim">
          <component
            v-if="customComponent"
            :is="customComponent"
            @close="closePopup"
            :parent-info="uploadParam"
            @set-dext-file-info="arrangeUploadParam"
          />
        </win-layer-pop>
      </div><!-- popup_cont -->
    </form>
  </div><!-- popup_wrap // -->
</template>

<script>
import docs from '@/api/rest/trans/docs'
import transCommon from '@/api/rest/trans/transCommon'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import DocUploadPop from '@/pages/trans/popup/DocUploadPop'
import EInputNumber from '@/components/common/EInputNumber'
import { rootComputed } from '@/store/helpers'

export default {
  name: 'CntrNoCorrectReqInputPop',
  components: {
    EInputNumber,
    DocUploadPop,
    WinLayerPop,
    rootComputed
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          bkgNo: ''
        }
      }
    }
  },
  data () {
    return {
      formData: {
        bkgNo: '',
        kind: '2',
        vslCd: '',
        voyNo: '',
        polCtrCd: '',
        polPortCd: '',
        podPortCd: '',
        shprCstNo: '',
        shprCstNm: '',
        reqRsnCont: '',
        blNo: '',
        reqPicNo: 'all',
        reqPicNm: '',
        reqPicTelNo: '',
        reqPicEmlAddr: '',
        reqPicNmS: '',
        containerList: [],
        //파일 정보
        uploadFileInfos: []
      },
      containerInfo: [
        {
          cntrSeq: '1',
          cntrNo: '',
          scgAppNo: '',
          dgCrtfNo: ''
        }
      ],
      dangerDocInfo: {
        vslCd: '',
        voyNo: '',
        polCtrCd: '',
        polPortCd: '',
        podCtrCd: '',
        podPortCd: '',
        shprCstNo: '',
        shprCstNm: '',
        reqRsnCont: '',
        blNo: ''
      },
      managerList: [],
      dgCrtfNoList: [],
      readOnly: false,
      //업로드 관련 파라미터(파일 첨부 시, 값이 담기고 CUD 시 parameter에 옮김)
      uploadParam: [],
      popupParams: {},
      customComponent: null,
      today: '',
      reqTempSaveYn: 'N'
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    this.formData = this.parentInfo
    this.init()
    // this.getDangerDocInfo()
  },
  methods: {
    async init () {
      const TH = this
      let tempInfoYn = 'N'

      // 오늘 날짜
      let now = moment()
      let today = now.format('YYYY/MM/DD')
      this.today = today

      // 로그인한 정보를 이용하여 프로필 정보 가져오기
      if (this.auth.userId) {
        const data = this.selectedProfile
        this.formData.entPicNo = data.picNo
        this.formData.reqPicNm = data.picNm
        this.formData.reqPicEmlAddr = data.emlAddr

        let usrTelNo = data.telPlcNo || ''

        if (this.$ekmtcCommon.isNotEmpty(data.telOfcNo)) {
          usrTelNo += '-' + data.telOfcNo
        }

        if (this.$ekmtcCommon.isNotEmpty(data.telNo)) {
          usrTelNo += '-' + data.telNo
        }

        this.formData.reqPicTelNo = usrTelNo
      }

      // 임시저장 정보 불러오기
      await docs.getReqDocTempInfo(this.parentInfo)
      .then(res => {
        console.log('getReqDocTempInfo res @@@@@ ', res)
        const data = res.data
        const containerList = []

        if (data.length > 0) {
          TH.formData = data[0]
          TH.reqTempSaveYn = 'Y'
          tempInfoYn = 'Y'

          for (var item of data) {
            containerList.push({
              cntrNo: item.cntrNo,
              bfCntrNo: item.bfCntrNo,
              updtCntrNo: item.updtCntrNo
            })
          }

          TH.containerInfo = containerList
          TH.formData.containerList = containerList
        }
      })

      // 임시저장정보 없으면 부킹정보 불러오기
      if (tempInfoYn !== 'Y') {
        await this.getReqDocTempBkgInfo()
      }
    },
    async cntrNoCorrectReqInsert () {
      console.log('cntrNoCorrectReqInsert @@@@@')

      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.MYIN040G010.061'), useConfirmBtn: true })) {
        this.formData.containerList = this.containerInfo
        this.formData.reqCatCd = this.parentInfo.reqCatCd

        docs.reqDocTempSubmit(this.formData)
          .then((response) => {
            console.log('reqDocTempSubmit response @@@@@@ ', response)
            if (response.headers.respcode === 'C0000') {
              const TH = this
              TH.$ekmtcCommon.alertCallback('저장되었습니다.', function () {
                TH.reqTempSaveYn = 'Y'
              })
          } else {
            TH.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.237')) // 요청 접수에 실패하였습니다.
          }
        })
        .catch(err => {
          console.log(err)
        }).finally(() => {
          // this.$emit('close', false)
        })
      } else {
        return false
      }
    },
    async cntrNoCorrectReqDelete () {
      console.log('cntrNoCorrectReqDelete @@@@@')

      if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('tem.CMAT023'), useConfirmBtn: true })) {
        this.formData.containerList = this.containerInfo
        this.formData.reqCatCd = this.parentInfo.reqCatCd

        docs.reqDocTempDelete(this.formData)
          .then((response) => {
            console.log('cntrNoCorrectReqDelete response @@@@@@ ', response)
            if (response.headers.respcode === 'C0000') {
              const TH = this
              TH.$ekmtcCommon.alertCallback(TH.$t('tem.CMAT012'), function () {
                TH.reqTempSaveYn = 'N'

                // 임시저장정보 삭제됬으니 부킹정보조회
                TH.getReqDocTempBkgInfo()
              })
          } else {
            TH.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.237')) // 요청 접수에 실패하였습니다.
          }
        })
        .catch(err => {
          console.log(err)
        }).finally(() => {
          // this.$emit('close', false)
        })
      } else {
        return false
      }
    },
    openPopup () {
        this.customComponent = DocUploadPop
        this.$ekmtcCommon.layerOpen('.doc_req_pop .popup_dim')
    },
    //파일첨부화면과 팝업을 나누기 위해서 설정
    closePopup () {
      console.log('---  closePopup')
      this.customComponent = null
      this.$ekmtcCommon.layerClose('.doc_req_pop .popup_dim')
    },
    arrangeUploadParam () {
      const attachSelector = document.querySelector('#file_att')
      this.$ekmtcCommon.hideErrorTooltip(attachSelector)
      this.uploadParam.map((item) => (item.newFlag = item.new))
    },
    addBLContainer () {
      const containerList = this.containerInfo
      const containerLen = containerList.length
      const cntrSeq = containerLen === 0 ? 1 : Number(containerList[containerLen - 1].cntrSeq) + 1
      containerList.push(
        {
            cntrSeq: cntrSeq,
            cntrNo: ''
          }
      )
    },
    removeBLContainer (idx) {
      const containerList = this.containerInfo
      containerList.splice(idx, 1)

      const containerLen = containerList.length

      if (containerLen === 0) {
        this.addBLContainer()
      }
    },
    changeOnlyUpperEng (e, object, key, idx) {
      let key2 = key
      if (key === 'bfCntrNo') {
        const frm = document.querySelector('#frm')
        const elem = frm.querySelector('#bfCntrNo_' + idx)
        this.$ekmtcCommon.hideErrorTooltip(elem)
        if (e.target.value) {
          const regEng = /^[a-zA-Z0-9]*$/
          const matchEng = regEng.test(e.target.value)
          if (!matchEng) {
            this.$ekmtcCommon.showErrorTooltip(elem, this.$t('art.CMATK215')) // 특수문자 또는 한글을 사용할 수 없습니다.
          }
        }
      } else if (key === 'updtCntrNo') { // updtCntrNo
        const frm = document.querySelector('#frm')
        const elem = frm.querySelector('#updtCntrNo_' + idx)
        key2 = 'updtCntrNo'
        this.$ekmtcCommon.hideErrorTooltip(elem)
        if (e.target.value) {
          const regEng = /^[a-zA-Z0-9]*$/
          const matchEng = regEng.test(e.target.value)
          if (!matchEng) {
            this.$ekmtcCommon.showErrorTooltip(elem, this.$t('art.CMATK215')) // 특수문자 또는 한글을 사용할 수 없습니다.
          }
        }
      }
      this.$ekmtcCommon.onlyEngNumber(e)
      this.$set(object, key2, object[key2].toUpperCase())
    },
    async reqCntrNoCorrSave () {
      let isOk = true
      const TH = this
      const formData = TH.formData

      if (!await this.checkValidationAll()) {
        isOk = false
      }

      if (isOk) {
        console.log('reqCntrNoCorrSave @@@@@@ ')

        let successFlag = true
        this.formData.containerList = this.containerInfo
        this.formData.reqCatCd = this.parentInfo.reqCatCd

        await docs.reqDocTempSubmit(this.formData)
          .then((response) => {
            console.log('reqDocTempSubmit response @@@@@@ ', response)
        })
        .catch(err => {
          console.log(err)
        }).finally(() => {
          // this.$emit('close', false)
        })
        formData.vslCd = TH.formData.vslCd
        formData.voyNo = TH.formData.voyNo
        formData.polCtrCd = TH.formData.polCtrCd
        formData.polPortCd = TH.formData.polPortCd
        formData.podCtrCd = TH.formData.podCtrCd
        formData.podPortCd = TH.formData.podPortCd
        formData.shprCstNo = TH.formData.shprCstNo
        formData.shprCstNm = TH.formData.shprCstNm
        formData.blNo = TH.parentInfo.blNo
        formData.bkgNo = TH.formData.bkgNo
        formData.reqCatCd = '17'

        let result = ''
        await docs.reqDocSubmit(formData).then(res => {
          if (res.headers.respcode !== 'C0000') {
            successFlag = false
          }
          result = res.data
        }).catch(() => {
          successFlag = false
        }).finally(() => {
          if (successFlag && result !== null) {
            TH.$ekmtcCommon.alertCallback(TH.$t('msg.CSBK100.236'), function () {
              TH.$emit('selectFunc', result)
              TH.$emit('close')
            })
          } else {
            TH.$ekmtcCommon.alertDefault(TH.$t('msg.CSBK100.237'))
          }
        })
      }
    },
    async checkValidationBfCntrNo (idx, flag) {
      let isOk = true
      const formData = this.formData
      const frm = document.querySelector('#frm')
      const bfSelector = frm.querySelector('#bfCntrNo_' + idx)
      const cntrInfo = this.containerInfo[idx]
      const containerInfo = this.containerInfo

      this.$ekmtcCommon.hideErrorTooltip(bfSelector)

      const params = {
        cntrNo: cntrInfo.cntrNo || '',
        bkgNo: formData.bkgNo,
        blNo: formData.blNo,
        type: formData.bkgNo !== '' ? 'BK' : 'BL',
        cntrKind: ''
      }

      if (this.$ekmtcCommon.isEmpty(bfSelector.value)) {
          this.$ekmtcCommon.showErrorTooltip(bfSelector, this.$t('art.CMATK363'))
          isOk = false
      }

      if (containerInfo.length > 0) {
        params.cntrKind = 'BF'
        params.cntrNo = cntrInfo.bfCntrNo

        await docs.checkCntrInBlBkg(params)
            .then(res => {
              const data = res.data

              if (!this.$ekmtcCommon.isEmpty(data.cntrNo)) {
                containerInfo.forEach((c, i) => {
                  if (i !== idx && c.bfCntrNo === cntrInfo.bfCntrNo) {
                    this.$ekmtcCommon.showErrorTooltip(bfSelector, this.$t('art.CMATK177'))
                    isOk = false
                  }
                })
              } else {
                  this.$ekmtcCommon.showErrorTooltip(bfSelector, this.$t('art.CMATK363'))
                  isOk = false
              }
            })
      }

      return isOk
    },
    async checkValidationAfCntrNo (idx) {
      let isOk = true
      const formData = this.formData
      const frm = document.querySelector('#frm')
      const updtSelector = frm.querySelector('#updtCntrNo_' + idx)
      const cntrInfo = this.containerInfo[idx]

      this.$ekmtcCommon.hideErrorTooltip(updtSelector)

      const params = {
        cntrNo: cntrInfo.cntrNo,
        bkgNo: formData.bkgNo,
        blNo: formData.blNo,
        type: formData.bkgNo !== '' ? 'BK' : 'BL',
        cntrKind: ''
      }

      params.cntrKind = 'AF'
      params.cntrNo = cntrInfo.updtCntrNo

      transCommon.findCntrInfo(params.cntrNo).then(res => {
        if (res.headers.respcode === 'C0000' && res.data.chkYn === 'Y') {
        //
        } else {
        //
        }
      }).catch(() => {
      }).finally(() => {
      })

      if (await this.$ekmtcCommon.isEmpty(updtSelector.value)) {
        this.$ekmtcCommon.showErrorTooltip(updtSelector, this.$t('art.CMATK363'))
        isOk = false
      }

        // await docs.checkCntrInBlBkg(params)
        //     .then(res => {
        //       console.log('checkCntrInBlBkg af @@@@@ ', res)

        //       const data = res.data

        //       if (data === 'N') {
        //         containerInfo.forEach((c, i) => {
        //           if (i !== idx && c.updtCntrNo === cntrInfo.updtCntrNo) {
        //             //this.$ekmtcCommon.showErrorTooltip(updtSelector, this.$t('art.CMATK177'))
        //             //isOk = false
        //           }
        //         })
        //       } else {
        //         this.$ekmtcCommon.showErrorTooltip(updtSelector, this.$t('art.CMATK363'))
        //         isOk = false
        //       }
        //     })

      return isOk
    },
    async checkValidationAll () {
      let isOk = true
      const prefix = ['shprCstEml', 'shprCstTelNo']
      const containerInfo = this.containerInfo

      prefix.forEach((el, idx) => {
        if (!this.checkValidationInputInfo(el, idx)) {
          isOk = false
        }
      })

      for (const [idx, val] of this.containerInfo.entries()) {
        if (!await this.checkValidationBfCntrNo(idx)) {
          isOk = false
        }

        if (!await this.checkValidationAfCntrNo(idx)) {
          isOk = false
        }
      }

      return isOk
    },
    checkValidationEmail (id) {
      const frm = document.querySelector('#frm')
      const selector = frm.querySelector('#' + id)

      if (selector === null) {
        return true
      }

      let isOk = true
      if (selector !== undefined && selector !== null && this.$ekmtcCommon.isNotEmpty(selector.value)) {
        if (selector.value.indexOf(',') > -1) {
          if (!this.$ekmtcCommon.multiCheckEmail(selector.value, ',')) {
            isOk = false
          }
        } else {
          if (!this.$ekmtcCommon.checkEmail(selector.value)) {
            isOk = false
          }
        }
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.VOSD100.043')) // 이메일 체크
      }

      return isOk
    },
    checkErrorVisible (id) {
      const frm = document.querySelector('#frm')
      const elem = frm.querySelector('#' + id)

      const parentElem = $(elem).parents('td, div').eq(0)
      const msgBox = parentElem.find('.tooltip_essen_box').eq(0)

      if (msgBox.length > 0 && msgBox.is(':visible') && this.$ekmtcCommon.isNotEmpty(elem.value)) {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
    },
    async getReqDocTempBkgInfo () {
      const TH = this

      await docs.getReqDocTempBkgInfo(this.parentInfo)
        .then(res => {
          console.log('getReqDocTempBkgInfo res @@@@@ ', res)
          const data = res.data
          data.containerList = []

          TH.formData = data

          // 컨테이너 입력칸 한줄추가
          if (TH.containerInfo.length === 0) {
            this.addBLContainer()
          }
        })
    },
    // 선택 밸리데이션
    checkValidationInputInfo (el, idx) {
      let isOk = true
      const frm = document.querySelector('#frm')
      const selector = frm.querySelector('#' + el)

      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (selector !== null && this.$ekmtcCommon.isEmpty(selector.value)) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
        isOk = false
      }

      return isOk
    }
  }
}
</script>

<style scoped>
text_center {
  text-align: center;
}
</style>
